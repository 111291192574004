.App-logo {
  height: 40px;
  width: 40px;
  border-top-left-radius: 80%;
  border-bottom-left-radius: 80%;
  border-top-right-radius: 80%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  .App-footer {
    animation: App-footer-spin infinite 20s
      cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-footer-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 636px) {
  /* I've given 1023px but you can change to proper width */
  .BodyDivider {
    display: none;
  }

  .YoutubeVideos {
    display: flex;
    justify-content: center;
  }

  .App-Email-sm {
    display: none;
  }
  .App-Email-lg {
    display: inline-block;
  }
}

.hubspot-link__icon {
  display: none;
}

.youTubeContainer {
  width: 100%;
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
  -webkit-transform: rotate(0.000001deg);
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  display: inline-flex;
}

.youTubeContainer .video {
  width: 100%;
  height: 410px;
}
